import { useState } from 'react';
import Grid from '@mui/material/Grid';
import { endOfDay, formatISO, startOfDay } from 'date-fns';

import { useRestaurantContext } from '@/contexts/restaurant';
import { useUserContext } from '@/contexts/user';
import { restrictRoles } from '@/constants/roleRestrictions';
import { ReportType } from '@/common/utility';

import { TimeRangeTypes, type ITimeRangeForm } from '@/components/TimeRange/Form/types';
import RevenueChart from '@/components/Dashboard/RevenueChart';
import BestsellingItems from '@/components/Dashboard/BestsellingItems';
import NewInfoCards from '@/components/Dashboard/InfoCards';
import Filters from '@/components/Dashboard/Filters';

export interface FilterProps {
    timeRange: ITimeRangeForm;
}

export default function Dashboard() {
    const { restaurant } = useRestaurantContext();
    const { user } = useUserContext();

    const todayDate = new Date();
    const [timeRange, setTimeRange] = useState<ITimeRangeForm>({
        type: TimeRangeTypes.TODAY,
        from: formatISO(startOfDay(todayDate)),
        to: formatISO(endOfDay(todayDate)),
    });

    const reportType = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role)
        ? ReportType.BRAND
        : ReportType.RESTAURANT;

    return (
        <Grid container spacing={2}>
            <Filters setTimeRange={setTimeRange} />
            <NewInfoCards timeRange={timeRange} />
            <RevenueChart timeRange={timeRange} type={reportType} />
            {restaurant?.config?.vendorShowBestsellingItems && <BestsellingItems />}
        </Grid>
    );
}
