import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import DashboardService from '@/services/dashboard';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { restrictRoles } from '@/constants/roleRestrictions';
import { getCurrencyWithAmount } from '@/common/utility';
import { timeRangeTitles } from '@/components/TimeRange/Form/hooks/useData';
import { IDinerFeeOption } from '@/views/OrdersTableView/types';
import { FilterProps } from '@/views/Dashboard';

import DashboardCard from '../DashboardCard';
import { IFetchDashboardDataResponse, ReportType } from '../types';
import OrderAndRevenue from '../OrderAndRevenue';
import { dashboardItemsData } from './data';

export default function NewInfoCards({ timeRange }: FilterProps) {
    const dashboardService = DashboardService.getInstance();

    const { user } = useUserContext();
    const { restaurant, selectedProductType, selectedQrGroup } = useRestaurantContext();
    const { t } = useTranslation('common');

    const type = restrictRoles.BRAND_DASHBOARD.includes(user?.userData.role) ? ReportType.BRAND : ReportType.RESTAURANT;
    const baseName = type === ReportType.RESTAURANT ? 'restaurant' : 'brand';
    const id = type === ReportType.RESTAURANT ? user.restaurantId : restaurant?.brand_id;

    const [dashboardData, setDashboardData] = useState<IFetchDashboardDataResponse>();
    const [dashboardItemsLoading, setDashboardItemsLoading] = useState<boolean>(false);
    const [dashboardItemsError, setDashboardItemsError] = useState<string>('');

    const showTip = !restaurant?.config?.disableTipForVendorUsers || false;
    const vendor_enableTipCommission = restaurant?.config?.vendor_enableTipCommission || false;
    const disableTipCardAndChart = restaurant?.restaurant_country?.config?.disableTipCardAndChart || false;
    const hideTip = vendor_enableTipCommission && disableTipCardAndChart;

    const range = timeRangeTitles(t)[timeRange.type];
    const selectedDasboardKeys = restaurant?.config?.selectedDashboardKeys || [];
    const showQlubDinerFee =
        !restaurant?.restaurant_country?.config?.dinerFeeVisibility ||
        (!restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideAll) &&
            !restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideDashboard));
    const showTipCard = showTip && !hideTip;
    const dashboardItems = dashboardItemsData({ range, selectedDasboardKeys, showQlubDinerFee, showTipCard, t });

    useEffect(() => {
        setDashboardItemsLoading(true);
        dashboardService
            .getDashboardData({
                name: baseName,
                id,
                qrGroups: selectedQrGroup ? [selectedQrGroup] : null,
                funnel: selectedProductType,
                period: timeRange.type,
                from: timeRange.from,
                to: timeRange.to,
            })
            .then((res) => {
                setDashboardData(res);
            })
            .catch((err) => {
                setDashboardItemsError(err.message);
            })
            .finally(() => {
                setDashboardItemsLoading(false);
            });
    }, [timeRange, selectedProductType, selectedQrGroup]);

    return dashboardItems.length > 0 ? (
        <>
            {dashboardItems.map((item) => {
                if (!item.enabled) return null;
                const { currencySymbol, currencyCode } = dashboardData || {
                    currencySymbol: '',
                    currencyCode: '',
                };
                const value =
                    item.key === 'orders'
                        ? `${dashboardData?.cards.find((card) => card.title === item.key)?.value || 0}`
                        : `${getCurrencyWithAmount(
                              dashboardData?.cards.find((card) => card.title === item.key)?.value,
                              currencySymbol,
                              currencyCode,
                          )}`;

                return (
                    <Grid item xs={6} sm={6} md={3} lg={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <DashboardCard
                                    headerText={item.dateFilterText}
                                    value={value}
                                    valueIcon={item.valueIcon1}
                                    tooltipText={item.tooltipKey}
                                    loading={dashboardItemsLoading}
                                    error={dashboardItemsError}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            {OrderAndRevenue()}
        </>
    ) : null;
}
