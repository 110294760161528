import { Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';

import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { onPushEvent } from '@/services/lib/gtm';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import { BrandScheduledReportRole } from '@/constants/roleRestrictions';
import TimeDrawer from '@/components/TimeRange/Drawer';
import { PrimaryButton } from '@/components/common';
import { type ITimeRangeForm, TimeRangeTypes } from '@/components/TimeRange/Form/types';

const Filters = ({ setTimeRange }: { setTimeRange: Dispatch<SetStateAction<ITimeRangeForm>> }) => {
    const { push } = useRouter();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const { restaurant, selectedProductType, setSelectedProductType, selectedQrGroup, setSelectedQrGroup } =
        useRestaurantContext();

    const productTypes = [
        { option: t('Qlub QR'), value: 'qlub' },
        { option: t('QSR'), value: 'qsr' },
        { option: t('Qlub Terminal'), value: 'softpos' },
    ];

    const isUserBrand = BrandScheduledReportRole.includes(user?.userData?.role);

    const todayDate = new Date();
    const minDate = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() - 90);

    const setSelectedGroupHandler = (group: string) => {
        if (group === 'All') {
            setSelectedQrGroup(null);
            return;
        }
        setSelectedQrGroup(group);
    };

    return (
        <>
            <Hidden only="xs">
                <Grid
                    item
                    sm={isUserBrand ? 6 : 4}
                    md={isUserBrand ? 6 : 5}
                    lg={6}
                    xl={isUserBrand ? 9 : 7}
                    sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <legend style={{ letterSpacing: '.2vw', color: 'rgba(0,0,0,.6)' }}>
                        <Typography>{t('Revenue Reports')}</Typography>
                    </legend>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={isUserBrand ? 6 : 8} md={isUserBrand ? 6 : 7} lg={6} xl={isUserBrand ? 3 : 5}>
                <Grid container spacing={2}>
                    {!isUserBrand && (
                        <Grid item xs={6} sm={4}>
                            <Select
                                size="small"
                                value={selectedQrGroup || 'All'}
                                onChange={(e) => {
                                    setSelectedGroupHandler(e.target.value || '');
                                }}
                                sx={{
                                    marginRight: '10px',
                                    borderRadius: '8px',
                                    width: '100%',
                                    textAlign: 'left',
                                    color: '#7d00d4',
                                    '.MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#7d00d480',
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#7d00d480',
                                    },
                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                        borderColor: '#7d00d480',
                                    },
                                    '.MuiSvgIcon-root ': {
                                        fill: '#7d00d4 !important',
                                    },
                                }}
                            >
                                <MenuItem value={'All'}>{t('All QR Groups')}</MenuItem>
                                {restaurant?.qr_groups?.map((group) => (
                                    <MenuItem value={group.id}>{group.name}</MenuItem>
                                ))}
                                <Grid
                                    item
                                    xs={12}
                                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
                                >
                                    <PrimaryButton
                                        sx={{
                                            marginTop: '10px',
                                            borderRadius: '8px',
                                            height: '40px',
                                            textTransform: 'none',
                                        }}
                                        onClick={() => {
                                            onPushEvent('vendor_qr-groups_create-icon-button-selected');
                                            push('/qr_code');
                                        }}
                                        text={t('Add a Qr Group')}
                                        size="medium"
                                    />
                                </Grid>
                            </Select>
                        </Grid>
                    )}
                    <Grid item xs={6} sm={isUserBrand ? 6 : 4}>
                        <Select
                            size="small"
                            value={selectedProductType || 'All'}
                            onChange={(e) => {
                                const val = e.target.value;
                                if (val === 'All') setSelectedProductType(null);
                                else setSelectedProductType(val);
                            }}
                            sx={{
                                marginRight: '10px',
                                borderRadius: '8px',
                                width: '100%',
                                textAlign: 'left',
                                color: '#7d00d4',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: '#7d00d480',
                                },
                                '.MuiSvgIcon-root ': {
                                    fill: '#7d00d4 !important',
                                },
                            }}
                        >
                            <MenuItem value={'All'}>{t('All Products')}</MenuItem>
                            {productTypes.map(({ option, value }) => (
                                <MenuItem key={value} value={value}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={isUserBrand ? 6 : 12} sm={isUserBrand ? 6 : 4}>
                        <TimeDrawer
                            onConfirm={(val) => {
                                onPushEvent(`user_use_filter_date_${val.type}`);
                                setTimeRange(val);
                            }}
                            options={[
                                TimeRangeTypes.TODAY,
                                TimeRangeTypes.YESTERDAY,
                                TimeRangeTypes.WEEK,
                                TimeRangeTypes.LAST_7_DAYS,
                                TimeRangeTypes.MONTH,
                                TimeRangeTypes.LAST_30_DAYS,
                            ]}
                            minDate={minDate}
                            timeSelectEnabled
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Filters;
